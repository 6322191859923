import React from 'react';
const Legend = () => {
    function handleClick(e) {
        e.preventDefault();
        document.querySelector('.mapContainer').classList.toggle("showLegendMobile"); 
    }

    return (
        <div className="mapContainer">
            <div onClick={handleClick} className="mobileIcon">
                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="30" height="30" viewBox="0 0 32 32" fill="#6e6e6e">
                    <title>info</title>
                    <path d="M14 9.5c0-0.825 0.675-1.5 1.5-1.5h1c0.825 0 1.5 0.675 1.5 1.5v1c0 0.825-0.675 1.5-1.5 1.5h-1c-0.825 0-1.5-0.675-1.5-1.5v-1z" />
                    <path d="M20 24h-8v-2h2v-6h-2v-2h6v8h2z" />
                    <path d="M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z" />
                </svg>
            </div>
            <div className="mapOverlay"></div>
            <div className="mapLegend">
                <span onClick={handleClick} aria-hidden="true" className="esri-collapse__icon esri-expand__icon--expanded esri-icon-collapse showCloseIcon"></span>
                <div className="legendContainer">
                    <span className="txt-title pt-3 pb-2 showCloseIcon">Legend</span>
                    <div className="d-flex justify-content-start districts align-items-center">
                        <svg overflow="hidden" width="14" height="14">
                            <g transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,7.00000000,7.00000000)">
                                <circle className="legendItem3" stroke="rgb(255, 255, 255)" strokeOpacity="0.5058823529411764" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" cx="0" cy="0" r="6" fillRule="evenodd" strokeDasharray="none" dojogfxstrokestyle="solid"></circle>
                            </g>
                        </svg>
                        <div className="legendText">Address</div>
                    </div>
                    <div className="d-flex justify-content-start align-items-center borough">
                        <svg overflow="hidden" width="14" height="14">
                            <g transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,7.00000000,7.00000000)">
                                <circle className="legendItem2" stroke="rgb(255, 255, 255)" strokeOpacity="0.5058823529411764" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" cx="0" cy="0" r="6" fillRule="evenodd" strokeDasharray="none" dojogfxstrokestyle="solid"></circle>
                            </g>
                        </svg>
                        <div className="legendText">Zoned schools</div>
                    </div>

                    <div className="d-flex justify-content-start align-items-center schools">
                            <svg overflow="hidden" width="14" height="14">
                                <g transform="matrix(1.00000000,0.00000000,0.00000000,1.00000000,7.00000000,7.00000000)">
                                    <circle className="legendItem1" stroke="rgb(255, 255, 255)" strokeOpacity="0.5058823529411764" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="4" cx="0" cy="0" r="6" fillRule="evenodd" strokeDasharray="none" dojogfxstrokestyle="solid"></circle>
                                </g>
                            </svg>
                        <div className="legendText">Schools within 1 mile</div>
                    </div>
                  </div>
            </div>
        </div>
    )
}

export default Legend