import React, { useContext } from 'react';
import { ZoneList } from './ZoneList';
import School from './School';
import { DataContext } from "../common/context/DataContext";

const ZoneSchoolContainer = () => {

    const context = useContext(DataContext)


    return (
        <div className="iScroll"  >

            {context.filterZoneData && context.zoneInfo ? (<ZoneList />) : null}

            {context.filterData ?
                <div>
                    <h2 className="h2-school-filter">Nearby Schools</h2>
                    <ul className="schoolList">
                        {
                            context.filterData ?

                                context.filterData.map((profile, i) => {
                                    return <School profile={profile} key={i} />
                                })
                                : null
                        }
                    </ul>
                </div>
                : null}

        </div>
    )

}
export default ZoneSchoolContainer;