import React, { Component, Fragment } from 'react';
import Autocomplete from 'react-autocomplete';
import AddressView from "./AddressView.js"
import { DataContext } from "../common/context/DataContext";
import DataService from "../common/service/DataService";

export class Search extends Component {

    static contextType = DataContext

    constructor(props) {
        super(props);

        this.state = {
            SearchtText: '',
            Grade: '',
            Borough: '',
            AutoCompleteData: [],
            AddressData: [],
            HasError: null,
            isLoading: false
        };

    } 

    handleChangeAutoComplete(e) {

        this.setState({ SearchtText: e.target.value });

        //If Search has more then 1 characters
        if (e.target.value.length > 1) {

            this.GetSchoolsAutoComplete(e.target.value);
        }

    }

    handleChangeGrade(e) {

        this.setState({ Grade: e.target.value });
    }

    handleChangeBorough(e) {

        this.setState({ Borough: e.target.value });
    }

    onSelectAddress(item) {

        return `${item.name}`;
    }

    handleSubmit(e) {

        e.preventDefault();

        this.setState({ isLoading: true })

        //this.context.resetState();

        this.reset();

        const splitAddress = this.SetSearchRequestType(this.state.SearchtText);

        //If Address in seach box
        if (splitAddress) {

            this.GetDisplayAddress(splitAddress, this)

            this.context.resetAction("multiaddress")
        }
        else {

            //Get All Schools
            this.context.getSchools(this.context.config.Data, {
                "search": this.state.SearchtText,
                "borough": this.state.Borough,
                "grade": this.state.Grade
            }).then(x => {
                this.setState({ isLoading: false })
            });
        }
        document.querySelectorAll("a#nav-school-tab")[0].click();
    }

    SetSearchRequestType(text) {
        text = text.trim();

        if (text) {

            this.setState({ isAddress: false });

            // Search for location code if wrapped in parentheses
            if (text.match(/\(.{4}\)/i)) {

                return null;

            } else if (text.length === 6 && text.match(/\d{2}[A-Za-z].{3}/i)) {
                // if a dbn is entered search by it

                return null;

            } else if (text.match(/^(\d+((\s|-)\d+)?)[\s]+([\w\d\s'-,]+)/i)) {

                //If it's an address request the address 
                this.setState({ isAddress: true });

                return text.match(/^(\d+((\s|-)\d+)?)[\s]+([\w\d\s'-,]+)/i);
            }
            else {
                return null;

            }
        }
    }

    GetDisplayAddress(splitAddress, _this) {

        var housenumber = splitAddress[1].trim();
        var streetname = splitAddress[4].trim();
        var boroughOrZipCode = _this.state.Borough;

        if (typeof splitAddress[2] !== 'undefined') {

            housenumber = splitAddress[1].trim().split(" ")[0];
            streetname = splitAddress[2].trim() + " " + splitAddress[4].trim();

            if (splitAddress[2].indexOf('-') > -1) {

                housenumber = splitAddress[1].trim();
                streetname = splitAddress[4].trim();
            }
        }

        DataService.GetAddress(this.context.config.Data, housenumber, streetname, boroughOrZipCode).then(function (results) {

            if (results && results.length === 1) {

                _this.setState({ AddressData: results, HasError: false, isLoading: false });


                //If only one address is there then show on map too
                _this.context.clickAddress(results[0], _this.state.Grade);
            }
            else if (results && results.length > 0) {

                _this.setState({ AddressData: results, HasError: false, isLoading: false });

            }
            else {

                _this.setState({ AddressData: [], HasError: true, isLoading: false });
            }

        }).catch(err => {

            _this.setState({ AddressData: [], HasError: true, isLoading: false });
        });
    }

    GetSchoolsAutoComplete(searchText) {

        const _this = this; 

        DataService.GetSchoolBySearch(this.context.config.Data , searchText).then(ParsedJSON => {

            _this.setState({ AutoCompleteData: [] });

            //Passing DB result to Container Component
            _this.setState({ AutoCompleteData: ParsedJSON });

        }).catch(err => {

            _this.setState({ AutoCompleteData: [] });

        });
    }

    reset() {

        //reset the State
        this.setState({ AddressData: [], HasError: false });

        this.context.resetState();

    }

    renderItem(item, isHighlighted) {

        return (
            <li className="menuItem" style={{ background: isHighlighted ? 'lightgray' : '' }} key={item.locationCode} >
                {item.name} - {item.locationCode} ( {item.primaryAddressLine} )
            </li>
        );
    }

    render() {

        return (

            <form onSubmit={this.handleSubmit.bind(this)}>

                <div className="search-box">
                    <div className="row flex-row">
                        <div className="column address-column col-xs-12">
                            <label htmlFor="states-autocomplete">School Name or Address</label>
                            <Fragment>
                                <Autocomplete
                                    inputProps={{ id: 'states-autocomplete' }}
                                    value={this.state.SearchtText}
                                    wrapperStyle={{ position: 'relative', width: '100%' }}
                                    items={this.state.AutoCompleteData}
                                    getItemValue={this.onSelectAddress.bind(this)}
                                    renderItem={this.renderItem}
                                    onChange={this.handleChangeAutoComplete.bind(this)}
                                    onSelect={value => this.setState({ SearchtText: value })}
                                    renderMenu={children => (
                                        <ul className="autoCompleteDropdown">
                                            {children}
                                        </ul>
                                    )}
                                />
                            </Fragment>
                        </div>
                        <div className="column grade-column col-xs-6">
                            <label htmlFor="Grade">Grade</label>
                            <select id="Grade" value={this.state.Grade} onChange={this.handleChangeGrade.bind(this)}>
                                <option value="">All</option>
                                <option value="3K">3K</option>
                                <option value="EL">Early Learn</option>
                                <option value="PK">Pre-K</option>
                                <option value="0K">Kindergarten</option>
                                <option value="01">Grade 1</option>
                                <option value="02">Grade 2</option>
                                <option value="03">Grade 3</option>
                                <option value="04">Grade 4</option>
                                <option value="05">Grade 5</option>
                                <option value="06">Grade 6</option>
                                <option value="07">Grade 7</option>
                                <option value="08">Grade 8</option>
                                <option value="09">Grade 9</option>
                                <option value="10">Grade 10</option>
                                <option value="11">Grade 11</option>
                                <option value="12">Grade 12</option>
                            </select>
                        </div>
                        <div className="column borough-column col-xs-6">
                            <label htmlFor="Borough">Borough</label>
                            <select id="Borough" value={this.state.Borough} onChange={this.handleChangeBorough.bind(this)}>
                                <option value="">All</option>
                                <option value="X">Bronx</option>
                                <option value="K">Brooklyn</option>
                                <option value="M">Manhattan</option>
                                <option value="Q">Queens</option>
                                <option value="R">Staten Island</option>
                            </select>
                        </div>
                        <div className="column submit-column col-xs-12">
                            <input type="submit" className="btn btn-primary" value={this.state.isLoading ? 'Loading..' : 'Search'}
                                disabled={this.state.isLoading} />

                        </div>
                    </div>

                    {/*Address Div Display here */}
                    {this.state.AddressData.length > 0 ? <AddressView addressData={this.state.AddressData} grade={this.state.Grade} borough={this.state.Borough} /> : null}

                    {/*No Address Found Error */}
                    {this.state.HasError ? <div role="alert" className="alert alert-danger alert-dismissible m-2 mt-4 fade show">Address not found. Check the address ie: house number, streetname, borough</div> : null}


                </div>
            </form >

        );
    }
}
