import './assets/css/stylesheet.css';
import React, { Component } from 'react';
import { Layout } from './components/Layout'; 
import ReactGA from 'react-ga';

function InitializeGoogleAnalytics() {
    //ReactGA.initialize('UA-174915049-1');
    ReactGA.initialize('UA-173985042-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
}

export default class App extends Component {
    render() {
        InitializeGoogleAnalytics();
        return (
            <Layout />
        );
    }
}
