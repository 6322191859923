import React, { Component } from 'react';
import logo from '../assets/images/logo.svg';

import ReactGA from 'react-ga';

function InitializeGoogleAnalytics() {
    //ReactGA.initialize('UA-174915049-1');
    ReactGA.initialize('UA-173985042-2');
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
}

export class Header extends Component {

    render() {
        //InitializeGoogleAnalytics();
        InitializeGoogleAnalytics();
        return (
            <header>                
                <div className="row">
                    <div className="col-12 top--header">
                        <div id="Header" className="d-flex justify-content-between align-items-center" data-placeholder-label="Non Searchable">
                            <div className="brand">
                                <a href="https://www.schools.nyc.gov/">
                                    <img src={logo} className="logo" alt="NYCDOE" />
                                    <span className="visually-hidden">New York City Department of Education</span>
                                </a>
                            </div>
                            <div className="selector-language">
                                <div className="dropdown">
                                    <div id="Language_TD787CBAF026_Col00" className="" data-placeholder-label="Non Searchable">
                                        <div id="google_translate_element" aria-hidden="true">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
