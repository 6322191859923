import React from 'react';
import School from './School';
import Utils from "../common/utils/Utils";

const Zone = (props) => {

    const elementarySchools = Utils.findSchoolfromZoneData(props.zoneData.ElementarySchools, props.zoneSchools);
    const middleSchools = Utils.findSchoolfromZoneData(props.zoneData.MiddleSchools, props.zoneSchools);
    const highSchools = Utils.findSchoolfromZoneData(props.zoneData.HighSchools, props.zoneSchools);

    return (
        <div id={props.yearType+"Div"}>

            <h2 className="h2-school-filter">{props.yearType} Zoned Schools</h2>
            <div className="card mb-3">
                <div className="card-body">
                    <ZoneItem schools={elementarySchools} remarks={props.zoneData.ElementarySchools.Remarks} title="Elementary" />
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <ZoneItem schools={middleSchools} remarks={props.zoneData.MiddleSchools.Remarks} title="Middle" />
                </div>
            </div>
            <div className="card mb-3">
                <div className="card-body">
                    <ZoneItem schools={highSchools} remarks={props.zoneData.HighSchools.Remarks} title="High" />
                </div>
            </div>
        </div >
    )
}

export default Zone;

const ZoneItem = (props) => {

    return (
        <div>

            <h2 className="h2-school-filter">{props.title} Schools</h2>
            {
                props.schools ?

                    <ul className="schoolList">
                        {
                            props.schools.map((profile, i) => {
                                return <School profile={profile} key={i} />
                            })
                        }
                    </ul>
                    : null
            }
            <i className="snall">{props.remarks}</i>
        </div>
    )
}



