import React, { Component } from 'react';
import CheckBoxes from './Checkbox';
import { DataContext } from "../common/context/DataContext";
import { setTimeout } from 'timers';
import Utils from '../common/utils/Utils.js';

var initialChechoxes = {
    grades: [
        { value: "3K", text: "3K" },
        { value: "EL", text: "Early Learn" },
        { value: "PK", text: "Pre-K" },
        { value: "0K", text: "Kindergarten" },
        { value: "01", text: "Grade 1" },
        { value: "02", text: "Grade 2" },
        { value: "03", text: "Grade 3" },
        { value: "04", text: "Grade 4" },
        { value: "05", text: "Grade 5" },
        { value: "06", text: "Grade 6" },
        { value: "07", text: "Grade 7" },
        { value: "08", text: "Grade 8" },
        { value: "09", text: "Grade 9" },
        { value: "10", text: "Grade 10" },
        { value: "11", text: "Grade 11" },
        { value: "12", text: "Grade 12" }
    ],
    years: [
        { value: "current", isChecked: false, text: "Current" },
        { value: "future", isChecked: false, text: "Future" }
    ],
    types: [
        { value: "Charter", text: "Charter" },
        { value: "DOE", text: "District" },
        { value: "NYCEEC", text: "NYCEEC" },
        { value: "PKC", text: "Pre-K Center" },
        { value: "Transfer", text: "Transfer" },
        { value: "D75", text: "District 75" }
    ],
    onlyZone: [{ value: "IsZone", text: "Show Zoned Schools" }],
    passingTags: {
        grades: {
            "3K": false,
            "EL": false,
            "PK": false,
            "0K": false,
            "01": false,
            "02": false,
            "03": false,
            "04": false,
            "05": false,
            "06": false,
            "07": false,
            "08": false,
            "09": false,
            "10": false,
            "11": false,
            "12": false,
        },
        type: {
            "Charter": false,
            "DOE": false,
            "NYCEEC": false,
            "PKC": false,
            "Transfer": false,
            "D75": false,
        },
        years: {
            "current": false,
            "future": false,
        },
        onlyZone: {
            "IsZone": false
        }
    }
}

export class Filters extends Component {

    static contextType = DataContext

    constructor(props) {

        super(props);
        this.state = initialChechoxes;

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {

        const _this = this;

        if (this.context.action === "search" || this.context.action === "mode") {

            document.getElementById("zonedDDDiv").style.display = "none";
            document.getElementById("yearDDDiv").style.display = "none";
            document.querySelectorAll('input[id="IsZone"]')[0].checked = false;
            document.querySelectorAll('input[id="future"]')[0].checked = false;
            document.querySelectorAll('input[id="current"]')[0].checked = false;
        }


        ////If Address Show/Hide Year chceckboxes
        //if (this.context.action === "radius") {
        //    document.getElementById("zonedDDDiv").style.display = "block";
        //}

        //Clear All Button
        Utils.ShowHideClearButton();

        this.state = initialChechoxes;

        setTimeout(() => {

            const selectedGrade = _this.context.selectedGrade;

            if (selectedGrade) {

                for (var node in document.querySelectorAll('input[type=checkbox]')) {

                    if (node.value === selectedGrade) {
                        node.checked = true; // node.disabled = true;
                    }
                }
            }

        }, 200);


    }

    clearAllCheckboxes() {

        //Uncheck all Checkboxes
        var checkboxes = new Array();
        checkboxes = document.querySelectorAll('input[type=checkbox]')
        for (var i = 0; i < checkboxes.length; i++) {
            checkboxes[i].checked = false;
        }

        if (this.context.zoneInfo) {
            document.querySelectorAll('input[id="IsZone"]')[0].checked = true;
            document.querySelectorAll('input[id="current"]')[0].checked = true;
            document.querySelectorAll('input[id="future"]')[0].checked = true;
        }

        this.setState(initialChechoxes);

        const _this = this;

        setTimeout(() => {

            _this.context.setfilterData(this.context.schoolData, this.context.zoneSchoolsData, true, false, null);

        }, 300);
    }

    handleChange(e, filterProp) {
         

        const name = e.target.innerText != "" ? e.target.previousElementSibling.value : e.target.name;

        //If tab enter trigger
        if (e.target.innerText != "") {
            if (e.target.previousElementSibling.checked)
                e.target.previousElementSibling.checked = false;
            else
                e.target.previousElementSibling.checked = true;
            ;
        }

        var isIsZoneChK = document.querySelectorAll('input[id="IsZone"]:checked').length

        this.setState(prevState => ({
            passingTags: {
                ...prevState.passingTags,
                [filterProp]: {
                    ...prevState.passingTags[filterProp],
                    [name]: !prevState.passingTags[filterProp][name]
                }
            }
        }));



        //Clear All Button
        Utils.ShowHideClearButton();

        const _this = this;

        setTimeout(() => {

            //Get All selected Filters
            const filterList = Utils.filteredCollected(_this.state.passingTags);

            //If SHow ZOne schools Checkbox select then remove neayBySchools
            var filteredSchools = null;
            filteredSchools = Utils.multiPropsFilter(_this.context.schoolData, filterList);


            // IF address and setup  Filter zone data as well
            var filteredZoneSchools = null;
            if (_this.context.zoneInfo) {

                filteredZoneSchools = Utils.multiPropsFilter(_this.context.zoneSchoolsData, filterList);
            }

            //If SHowZOne only is selcted then setup ZOne Data
            let zoneStatus = null;
            if (isIsZoneChK > 0) {

                //Get Zone Status for ZOne List Component
                zoneStatus = Utils.GetSetZoneStatus();

                //Filter Zones Schools By Year
                if (filteredZoneSchools)
                    filteredZoneSchools = Utils.filterZoneSchools(filteredZoneSchools, _this.context.zoneInfo);
            }

            //If SHowZone only is UnSelcted then setup ZOne Data = null
            else {

                filteredZoneSchools = null;
            }

            _this.context.setfilterData(filteredSchools, filteredZoneSchools, true, filteredSchools == null && filteredZoneSchools == null ? true : false, zoneStatus);

        }, 500);

    };

    render() {

        return (
            <div id="mainDiv" className="filterContainer">

                <button className="col-md-6 mt-2 btn btn-secondary" id="clearAllBtn" onClick={() => this.clearAllCheckboxes()}>Clear All Filters</button>

                <div id="zonedDDDiv">
                    <h2 className="title">Zoned Schools</h2>
                    <CheckBoxes handleChange={e => this.handleChange(e, "onlyZone")} data={this.state.onlyZone} />
                </div>

                <div id="yearDDDiv">
                    <h2 className="title">School Year</h2>
                    <CheckBoxes handleChange={e => this.handleChange(e, "years")} data={this.state.years} />
                </div>

                <h2 className="title">Grade Level</h2>
                <CheckBoxes handleChange={e => this.handleChange(e, "grades")} data={this.state.grades} role="checkbox" tabindex="0" />

                <h2 className="title">School Type</h2>
                <CheckBoxes handleChange={e => this.handleChange(e, "type")} data={this.state.types} />

            </div>
        );
    }
}

