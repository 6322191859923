import React, { useContext } from 'react';
import { DataContext } from "../common/context/DataContext";
import Utils from '../common/utils/Utils.js';

const School = (props) => {

    const context = useContext(DataContext)

    const profile = props.profile;
    const badgeClass = Utils.schoolTypeBadgeCSSclass(profile.type);
    const schoolLink = "https://www.schools.nyc.gov/schools/" + profile.locationCode

    function handleClick(e) {
        e.preventDefault();
        if (window.innerWidth <= 960) {
            document.getElementById('nav-map-tab').click();
            document.body.classList.add('smallMap')
            context.openPopup(profile.locationCode);
        } else {
            context.openPopup(profile.locationCode);
        }
    }

    return (

        <li key={profile.locationCode}>
            <div className="item school-item" data-name={profile.name} data-lat={profile.x} data-lng={profile.y} >
                <div className="content" onMouseOver={() => context.mouseOvers(profile.locationCode)} onMouseOut={() => context.mouseOut()} >
                    <div className="flex-row" data-item={profile.item}>
                        <div className="column">
                            <h2 className="title">
                                <a href={schoolLink} target="_blank" className="more">{profile.name} - {profile.locationCode}</a>
                            </h2>
                        </div>

                        <div className="column address pt-1">
                            <h3 className="visually-hidden">Address</h3>
                            <div>{profile.primaryAddressLine} </div><div> {profile.boroughName}, {profile.zip}</div>
                            <div>{profile.phoneNumber}</div>
                        </div>

                        <div className="column info">
                            {
                                profile.grades !== "" ? <div>
                                    <span>Grades: </span>
                                    <span>{profile.grades}</span>
                                </div>
                                    : null
                            }
                            {
                                profile.district !== "" ?
                                    <div>
                                        <span>District: </span>
                                        <span>{profile.district}</span>
                                    </div>
                                    : null
                            }
                            {
                                profile.distance !== "" ?
                                    <div>
                                        <span>Distance: </span>
                                        <span>{parseFloat(profile.distance).toFixed(2)} miles</span>
                                    </div>
                                    : null
                            }

                            <div className={`badge badge-text ${badgeClass}`}>
                                {Utils.getTypeLabel(profile.type)}
                            </div>
                            {/*
                            <a href="#" onClick={handleClick} >
                                <div className="badge badge-text quick-view" >Quick View</div>
                            </a>
                             */ }

                        </div>


                    </div>
                </div>
            </div>
        </li>

    );
}

export default School;
