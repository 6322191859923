import React from 'react'; 
export class BreadCrumb extends React.Component {

    render() {
        return (
            <div className="row breadcrumb">
                <div className="col-12">
                    <nav className="" aria-label="Breadcrumbs">
                        <ul className="breadcrumb mt-1 mb-1">
                            <li className="pl-0"><a href="https://www.schools.nyc.gov/">Home </a></li>
                            <li className="active">Find a School</li> 
                        </ul>
                    </nav>
                </div>
            </div>
        );
    }
}
