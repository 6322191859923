export default class DataService {

    static GetSchoolsByLocCodes = (schoolsearchURL, locodes) => {

        return new Promise((resolve, reject) => {

            fetch(schoolsearchURL + "GetByLocationCodes?locationCodes=" + locodes, {
                method: 'GET',
                cache: "no-cache"
            })
                .then(response => {
                    if (response.ok) {
                        return response.json().then(jsons => {

                            return jsons;
                        }).catch(error => {

                            return null;
                        });

                    }
                })
                .then(function (results) {

                    resolve(results)
                })
                .catch(error => {
                    ////console.log("Get Schools By LocCodes:" + error);
                    reject(error)
                });

        });
    }

    static GetByLocationCodesByXY = (schoolsearchURL, locodes, x = null, y = null) => {

        return new Promise((resolve, reject) => {

            fetch(schoolsearchURL + "GetByLocationCodesByXY?x=" + x + "&y=" + y + "&locationCodes=" + locodes, {
                method: 'GET',
                cache: "no-cache"
            })
                .then(response => {
                    if (response.ok) {
                        return response.json().then(jsons => {

                            return jsons;
                        }).catch(error => {

                            return null;
                        });

                    }
                })
                .then(function (results) {

                    resolve(results)
                })
                .catch(error => {
                    //console.log("Get Schools By LocCodes:" + error);
                    reject(error)
                });

        });
    }

    static GetSchools = (schoolsearchURL, request) => {

        return new Promise((resolve, reject) => {

            fetch(schoolsearchURL + "GetSchools?search=" + request.search + "&borough=" + request.borough + "&grade=" + request.grade, {
                method: 'GET',
                cache: "no-cache"
            }).then(res => res.json())
                .then(function (results) {

                    resolve(results)
                })
                .catch(error => {
                    //console.log("GetSchools:" + error);
                    reject(error)
                });

        });
    }


    static GetAddress = (schoolsearchURL, housenumber, streetname, boroughOrZipCode) => {

        return new Promise((resolve, reject) => {

            fetch(schoolsearchURL+ "GetAddress?housenumber=" + housenumber + "&streetname=" + streetname + "&boroughOrZipCode=" + boroughOrZipCode, {
                method: 'GET',
                cache: "no-cache"
            }).then(res => res.json())
                .then(function (results) {

                    resolve(results)
                })
                .catch(error => {
                    //console.log("Get Address :" + error);
                    reject(error)
                });

        });
    }


    static GetSchoolBySearch = (schoolsearchURL, searchText) => {

        return new Promise((resolve, reject) => {

            fetch(schoolsearchURL+ "GetSchoolsBySearch?search=" + searchText, {
                method: 'GET',
                cache: "no-cache"
            }).then(res => res.json())
                .then(function (results) {

                    resolve(results)
                })
                .catch(error => {
                    //console.log(" GetSchoolsBySearch :" + error);
                    reject(error)
                });
        });
    }


}