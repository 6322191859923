import React from 'react';
import { loadModules } from 'esri-loader';
import config from "../configuration/webconfig";
import MapHelper from "../common/utils/MapHelper";
import DataService from "../common/service/DataService";
import { DataContext } from "../common/context/DataContext";
import marker from '../assets/images/map-icon.png';
import Loader from "../common/loader/Loader";
import Utils from '../common/utils/Utils.js';
import Legend from './Legend';

const styles = {
    container: {
        height: '100%',
        width: '100%'
    },
    mapDiv: {
        padding: 0,
        margin: 0,
        height: '100%',
        width: '100%'
    },
    Loader: {
        display: "none"
    }
}

export class MapView extends React.Component {

    static contextType = DataContext

    constructor(props) {

        super(props); 

        this.map = null;
        this.view = null;
        this._mapLayers = null;
        this._modeLayer = null;
        this._iconLayer = null;
        this._initialExtent = null;
        this._highlightSymbol = null;
        this._symbol = null;
        this._selectedSymbol = null;
        this._activeModeLayer = null;
        this._geometryService = null;
        this._schoolQueryTask = null;
        this._mode = 1;
        this._radiusSymbol = null;
        this._zonesTask = null;
        this._marker = marker;

    }

    componentDidMount() {

        const _this = this;

        loadModules(['esri/Map', "esri/views/MapView", "esri/widgets/LayerList", "esri/widgets/Expand", "esri/geometry/Extent", "esri/symbols/SimpleFillSymbol", "esri/layers/GraphicsLayer", "esri/symbols/SimpleLineSymbol", "esri/Color","dojo/domReady!"], config.options)
            .then(([Map, MapView, LayerList, Expand, Extent, SimpleFillSymbol, GraphicsLayer, SimpleLineSymbol, Color]) => {

                _this._modeLayer = new GraphicsLayer({
                    id: "modeLayer",
                    listMode: 'hide'
                });


                _this._iconLayer = new GraphicsLayer({
                    title: "school pins",
                    listMode: 'hide',
                    id: "iconLayer"
                });
               

                _this._activeModeLayer = new GraphicsLayer({
                    id: "active",
                    listMode: 'hide'
                });

                _this._highlightSymbol = new SimpleFillSymbol(
                    SimpleFillSymbol.STYLE_SOLID,
                    new SimpleLineSymbol(
                        SimpleLineSymbol.STYLE_SOLID,
                        new Color([0, 0, 0, 0.7]),
                        1),
                    new Color([168, 168, 168, 0.4]))

                _this._symbol = new SimpleFillSymbol(
                    SimpleFillSymbol.STYLE_SOLID,
                    new SimpleLineSymbol(
                        SimpleLineSymbol.STYLE_DASHDOT,
                        new Color([0, 0, 0, 0]),
                        1),
                    new Color([149, 149, 149, 0]))

                _this._selectedSymbol = new SimpleFillSymbol(
                    SimpleFillSymbol.STYLE_SOLID,
                    new SimpleLineSymbol(
                        SimpleLineSymbol.STYLE_SOLID,
                        new Color([255, 0, 0, 1]),
                        2),
                    new Color([255, 168, 168, 0.3]));

                _this._radiusSymbol = new SimpleFillSymbol(config.symbol.radius);

                _this._initialExtent = new Extent(config.initialExtent);

                //Setup Map 
                const map = new Map({
                    basemap: "topo", 
                    center: [-73.997478615, 40.712550848],
                    sliderStyle: "small",
                    zoom: 10,
                    maxZoom: 18,
                    minZoom: 10,
                    logo: false
                });

                //Setup View
                const view = new MapView({
                    container: "viewDiv",
                    map,
                    extent: _this._initialExtent,
                    popup: {
                        highlightEnabled: true,
                        highlightOptions: {
                            color: [255, 241, 58],
                            fillOpacity: 0.4
                        },
                        dockEnabled: true
                    }

                });


                view.ui.move("zoom", "top-left");
                view.ui.remove("attribution");
                view.ui.add("info", "top-left");


                //When View is Ready 
                view.when(function () {

                    //Create widget for Layers ON/Off on the top right
                    var layerList = new LayerList({
                        view: view,
                    });

                    var bgExpand = new Expand({
                        view: view,
                        content: layerList,
                        expandIconClass: "esri-icon-layers"
                    });


                    // Add widget to the top right corner of the view
                    view.ui.add(bgExpand, "top-right");


                    _this._mapLayers.push(_this._iconLayer);
                    _this._mapLayers.push(_this._activeModeLayer);
                   
                    //Add basemap layers to Map
                    _this._mapLayers.map(function (layer) {
                         
                        _this.map.add(layer);  
                    }); 

                    //Hide Loader
                    Utils.hideLoader(_this);

                })


                //When pointer move
                view.on("pointer-move", function (event) {
                    view.hitTest(event).then(response => MapHelper.onHoverMap(response, _this))
                });


                //When pointer Click 
                view.on("click", function (event) {
                    view.hitTest(event).then(response => _this.onClickHighlightArea(response))
                });



                this.map = map;
                this.view = view;

            });
    }

    shouldComponentUpdate() {

        return false;
    }

    async componentDidUpdate() {

        const dataContext = this.context; 

        switch (dataContext.action) {

            case "address":

                //HightLight The div for Selected Mode  
                for (var x = 0; x <= 2; x++) {

                    this.refs.MapModes.childNodes[x].className = "mode"
                }

                this.refs.MapModes.childNodes[0].className = "mode selected"

                this._iconLayer.removeAll();
                this._activeModeLayer.removeAll();

                this.displayAddressArea(dataContext.selectedAddress);

                break;


            case "openPopup":

                this.view.popup.close();

                var graphic = this._iconLayer.graphics.items.filter(item => {

                    return item.attributes && item.attributes["LocCode"] === dataContext.hoverItem

                })[0]

                this.view.popup.open({
                    location: graphic.geometry,
                    features: [graphic]
                });

                this.view.goTo(graphic);

                break;

            case "radius":

                this._modeLayer.removeAll();

                //Show Loader
                Utils.showLoader(this);

                //Display Schools Points on the map
                if (dataContext.schoolData) {

                    MapHelper.displaySchoolPoints(dataContext.schoolData, "S", this);
                }

                //Display Zone Schools POints on the map
                if (dataContext.zoneSchoolsData) {

                    MapHelper.displaySchoolPoints(dataContext.zoneSchoolsData, "Z", this).then(res => {

                        Utils.hideLoader(this);
                    });
                }

                if (!dataContext.schoolData || !dataContext.zoneSchoolsData) {
                    Utils.hideLoader(this);
                }


                break;

            case "search":

                MapHelper.clearLayers(this);

                //HightLight The div for Selected Mode  
                for (var x = 0; x <= 2; x++) {

                    this.refs.MapModes.childNodes[x].className = "mode"
                }

                this.refs.MapModes.childNodes[0].className = "mode selected";

                //Display Schools Points on the map
                if (dataContext.filterData) {

                    //Show Loader
                    Utils.showLoader(this);

                    const _this = this;

                    MapHelper.displaySchoolPoints(dataContext.filterData, "S", this).then(res => {

                        Utils.hideLoader(this);

                        if (dataContext.filterData.length === 1) {

                            _this.view.popup.close();

                            var graphic = _this._iconLayer.graphics.items[0];

                            _this.view.popup.open({
                                location: graphic.geometry,
                                features: [graphic]
                            });

                            _this.view.goTo(graphic);
                        }

                        _this.view.goTo(_this._initialExtent);
                    });


                }

                else {

                    Utils.hideLoader(this);
                }
                break;

            case "filter":

                //Hide all points except Address Point
                MapHelper.hideAllPoints(this);

                //Display Schools Points on the map
                if (dataContext.filterData)
                    MapHelper.showPointsByType(dataContext.filterData, "S", this)

                //Display Zone Schools Points on the map
                if (dataContext.filterZoneData)
                    MapHelper.showPointsByType(dataContext.filterZoneData, "Z", this)

                break;

            case "mode":


                //Display Schools Points on the map
                if (dataContext.schoolData) {

                    //Show Loader
                    Utils.showLoader(this);

                    MapHelper.displaySchoolPoints(dataContext.schoolData, "S", this).then(res => {

                        //HideLoader
                        Utils.hideLoader(this);

                    });
                }
                break;

            case "smouseover":

                MapHelper.mouseHoverPoint(dataContext.hoverItem, this);
                break;

            case "smouseout":

                MapHelper.mouseOutPoint();

                break;

            case "multiaddress":

                //Clear the Icons on the Map
                this._iconLayer.removeAll();

                break;

            case "setconfig":

                const _this = this;

                loadModules(["esri/layers/TileLayer", "esri/tasks/GeometryService", "esri/tasks/QueryTask", "dojo/domReady!"], config.options)
                    .then(([TileLayer,GeometryService, QueryTask]) => {
                         
                        _this._geometryService = new GeometryService(dataContext.config.Geometry);

                        _this._schoolQueryTask = new QueryTask(dataContext.config.Map + config.endpoints.schoolLabels);

                        _this._mapLayers = config.layers.map(function (layer) {

                            layer.url = _this.context.config.Map + layer.url;

                            return  (new TileLayer(layer));  
                        }); 
                         
                    });


                break;
            case null:

                Utils.showLoader(this);

                break;

            default:

                if (this.view && this.view.popup)
                    this.view.popup.close();

                if (this._activeModeLayer)
                    this._activeModeLayer.removeAll();

                break;

        }
    }

    async displayAddressArea(data) {

        //Get Point Promises
        const pointPromise = MapHelper.getpoint(data.x, data.y);
        const addressCenterPoint = await pointPromise;

        //Clear all icons
        this._iconLayer.removeAll();

        // Point Projection  
        const projectParameterPromise = await MapHelper.getProjectParameter([addressCenterPoint], this);
        const projectCenterPoint = await projectParameterPromise[0];


        //Display Point 
        await MapHelper.createPoint(config.symbol.addressPin, projectCenterPoint, this, "addressP");

        //Create Radius
        const radiusPromise = await MapHelper.displayRadius(projectCenterPoint, this);
        const radiusGeometry = await radiusPromise;


        ////////////////////// Near By Schools Data //////////////////////////////
        //Near By schools from Geometry
        const geometryPromise = await MapHelper.getNearBySchoolsByGeometry(radiusGeometry, ["LOC_CODE"], this);
        const nearBySchoolLocCodes = await geometryPromise;

        //Near By Schools Points and Display

        var neayBySchools = null;
        if (nearBySchoolLocCodes && nearBySchoolLocCodes.length > 0) {
            const strnearBySchoolLocCodes = nearBySchoolLocCodes.join();

            const neayBySchoolsPromise = await DataService.GetByLocationCodesByXY(this.context.config.Data, strnearBySchoolLocCodes, projectCenterPoint.x, projectCenterPoint.y);
            neayBySchools = await neayBySchoolsPromise;

        }

        ////////////////////// ZONE Data //////////////////////////////
        const zoningData = data.geoInfo.SchoolInfo;
        const zoneLocCodes = Utils.getUniqueLocCode(zoningData);

        //Zone Points and Display
        var zoneSchools = null;
        if (zoneLocCodes && zoneLocCodes.length > 0) {
            const strzoneLocCodes = zoneLocCodes.join();

            const zoneSchoolsPromise = await DataService.GetByLocationCodesByXY(this.context.config.Data, strzoneLocCodes, projectCenterPoint.x, projectCenterPoint.y);
            zoneSchools = await zoneSchoolsPromise;

        }


        //Filter Schools if Grade selected
        if (this.context.selectedGrade != "") {

            zoneSchools = zoneSchools.length > 0 ? zoneSchools.filter(item => {
                return item.grades.indexOf(this.context.selectedGrade) != "-1";
            }) : [];

            neayBySchools = neayBySchools.length > 0 ? neayBySchools.filter(item => {

                return item.grades.indexOf(this.context.selectedGrade) != "-1";
            }) : [];
        }

        neayBySchools = neayBySchools && neayBySchools.length === 0 ? null : neayBySchools;
        zoneSchools = zoneSchools && zoneSchools.length === 0 ? null : zoneSchools;

        //Update the Context
        this.context.setSchoolZoneData(neayBySchools, zoneSchools, zoningData, this.context.selectedGrade);
    }

    async onChangeMapMode(e) {
         
        this.context.resetState();

        //Show Loader
        Utils.showLoader(this);

        //Reset Data Set in Context
        this.context.resetState("reset");

        MapHelper.clearLayers(this);


        //HightLight The div for Selected Mode  
        for (var x = 0; x <= 2; x++) {

            this.refs.MapModes.childNodes[x].className = "mode"
        }

        e.target.classList.add("selected")

        var querytaskURL = null; var outFields = null;

        switch (e.target.textContent) {

            case "Neighborhoods":

                querytaskURL = this.context.config.Map + config.mapMode.neighborhoods.service;
                this._mode = 2;

                if (document.getElementById("addressDiv"))
                    document.getElementById("addressDiv").style.display = "none";

                outFields = config.mapMode.neighborhoods.outFields
                break;

            case "Districts":

                querytaskURL = this.context.config.Map + config.mapMode.districts.service
                this._mode = 3;

                if (document.getElementById("addressDiv"))
                    document.getElementById("addressDiv").style.display = "none";

                outFields = config.mapMode.districts.outFields
                break;

            default:
                this._mode = 1;
                Utils.hideLoader(this);
                break;

        }

        //If not Standard Mode
        if (this._mode === 2 || this._mode === 3) {

            const queryPromise = await MapHelper.getQueryTask(querytaskURL, outFields, "1=1", this);
            const queryData = await queryPromise;


            var symbol = this._symbol
            var features = queryData.features.map(function (graphic) {
                graphic.symbol = symbol;
                return graphic;
            });

            //Add returning Graphics from Query to Mode Layer
            this._modeLayer.addMany(features);

            //Adding Mode Layer to Map
            this.map.add(this._modeLayer, 2);

            //// animate to the results after they are added to the map
            this.view.goTo(features);

            //Hide Loader
            Utils.hideLoader(this);
        }

    }

    async onClickHighlightArea(response) {

        const _this = this

        //_this.context.setSchoolsData(schools, "mode"); 

        if (response.results.length > 0) {

            //Get Hight light Area 
            var modefeature = response.results.filter(function (result) {

                return result.graphic.layer === _this._modeLayer;
            });

            var iconfeature = response.results.filter(function (result) {

                return result.graphic.layer === _this._iconLayer;
            });


            if (iconfeature.length === 0 && modefeature.length > 0) {

                var modefeature1 = modefeature[0].graphic;

                _this.view.goTo(modefeature1);

                //Clear Highlighted Part
                _this._activeModeLayer.removeAll();


                loadModules(['esri/Graphic'], config.options).then(([Graphic]) => {

                    _this._activeModeLayer.add(new Graphic(modefeature1.geometry, _this._selectedSymbol));

                    //Adding Address Layer to Map
                    _this.map.add(_this._activeModeLayer, 2);
                });

                //Get Schools by gromtry
                const geometryPromise = await MapHelper.getNearBySchoolsByGeometry(modefeature1.geometry, "LOC_CODE", this);
                const locCodes = await geometryPromise;

                //Clear Icons
                _this._iconLayer.removeAll();

                //Get Schools by Loc Codes and Then Display 
                DataService.GetSchoolsByLocCodes(_this.context.config.Data, locCodes).then(function (schools) {

                    //Set Schools to Context
                    _this.context.setSchoolsData(schools, "mode");
                })

            }
        }
    }
  
    render() {
        const msg = "Changing the map view will clear your search results. Are you sure you want to proceed?";
        return (
            <div style={styles.container} className="mapDiv">
                <div id='viewDiv' style={styles.mapDiv}>
                    <div ref="loaderRef" ><Loader style={styles.Loader} /></div>
                    
                    <div className="activelayerselect">
                        <div className="activelayerpanel" ref="MapModes">
                            <button className='mode selected' onClick={(e) => { if (window.confirm(msg)) { this.onChangeMapMode(e) } }}   >Standard</button>
                            <button className='mode' onClick={(e) => { if (window.confirm(msg)) { this.onChangeMapMode(e) } }} >Neighborhoods</button>
                            <button className='mode' onClick={(e) => { if (window.confirm(msg)) { this.onChangeMapMode(e) } }} >Districts</button>
                        </div>
                    </div>

                    <div className="mapInfoContainer alert-info" role="alert" id="info" ref="mapInfoContainer">
                        <div className="mapInfo" ref="infoDiv"></div>
                    </div>

                    <Legend />
                </div>
            </div >
        )
    }
} 