import React from 'react';
import School from './School';
import { setTimeout } from 'timers';
import { DataContext } from "../common/context/DataContext";

export class SchoolList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            schoollist: [],

        };
    }

    static contextType = DataContext

    componentWillReceiveProps(props) {

        setTimeout(() => {

            if (this.context.resetlist === true) {
                this.setState({ schoollist: ([...props.schoollist.slice(0, 5)]) });
                this.context.setfilterData(props.schoollist, null, null, false);
            }
        }, 300);
    }

    componentWillMount() {

        this.setState((prevState) => ({ schoollist: ([...this.props.schoollist.slice(0, prevState.schoollist.length + 5)]) }));

    }

    componentDidMount() {

        const _this = this;
        this.refs.iScroll.addEventListener("scroll", () => {
         
            if (_this.refs.iScroll.scrollTop + _this.refs.iScroll.clientHeight >= _this.refs.iScroll.scrollHeight-5) {

                setTimeout(() => {
                    _this.setState((prevState) => ({
                        schoollist: ([...prevState.schoollist, ...this.props.schoollist.slice(prevState.schoollist.length, prevState.schoollist.length + 5)])
                    }));
                }, 500);
            }
        });


    }

    render() {

        return (

            <div role="feed" aria-busy="false" className="iScroll" ref="iScroll">
                <ul className="schoolList d-block">

                    {this.state.schoollist.length > 0 ?

                        this.state.schoollist.map((profile, i) => {
                            return <School profile={profile} key={i} />

                        })

                        : null
                    }

                </ul>
            </div>

        );
    }
}




