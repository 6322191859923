import React from 'react';
import { DataContext } from "../common/context/DataContext";

const CheckBoxes = props => {
    var contextType = DataContext 
    
    return (

        <div className="border">
            <ul className="p-3">
                {
                    props.data.map((item) => {
                        if (contextType.selectedGrade ===item.value) { item.isChecked = true; }
                        return (
                            <li key={item.value} >
                                <label key="lblGradeEL">
                                    <input className="filterCheckbox" id={item.value} name={item.value} key={item.value} onChange={props.handleChange} type="checkbox" value={item.value} />
                                    <span role="checkbox" tabindex="0" onKeyPress={event => {
                                         // if enter press then trigger it
                                        if (event.key === 'Enter') {
                                            props.handleChange(event)
                                        }
                                    }}  >{item.text}</span>
                                </label>
                            </li>
                        )
                    })
                }

            </ul>
        </div>


    )
}


export default CheckBoxes;