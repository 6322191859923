
export default class Utils {

    static isEquivalent = (a, b) => {

        if (a === null && b === null) {
            return true
        }
        else if ((a !== null && b === null) || (a === null && b !== null)) {
            return false
        }

        var aProps = Object.getOwnPropertyNames(a);
        var bProps = Object.getOwnPropertyNames(b);

        // If number of properties is different,
        // objects are not equivalent
        if (aProps.length !== bProps.length) {
            return false;
        }

        for (var i = 0; i < aProps.length; i++) {
            var propName = aProps[i];

            // If values of same property are not equal,
            // objects are not equivalent
            if (a[propName] !== b[propName]) {
                return false;
            }
        }

        // If we made it this far, objects
        // are considered equivalent
        return true;

    }

    static isZoneEqual = (current, future) => {

        var CE = current.ElementarySchools.hasOwnProperty("SchoolInfo") ? current.ElementarySchools.SchoolInfo : null;
        var CM = current.MiddleSchools.hasOwnProperty("SchoolInfo") ? current.MiddleSchools.SchoolInfo : null;
        var CH = current.HighSchools.hasOwnProperty("SchoolInfo") ? current.HighSchools.SchoolInfo : null

        var NE = future.ElementarySchools.hasOwnProperty("SchoolInfo") ? future.ElementarySchools.SchoolInfo : null;
        var NM = future.MiddleSchools.hasOwnProperty("SchoolInfo") ? future.MiddleSchools.SchoolInfo : null;
        var NH = future.HighSchools.hasOwnProperty("SchoolInfo") ? future.HighSchools.SchoolInfo : null


        return (Utils.isEquivalent(CE, NE)
            && Utils.isEquivalent(CM, NM)
            && Utils.isEquivalent(CH, NH)
            //&& current.HighSchools.Remarks.trim() === future.HighSchools.Remarks.trim()
            //&& current.ElementarySchools.Remarks.trim() === future.ElementarySchools.Remarks.trim()
            //&& current.MiddleSchools.Remarks.trim() === future.MiddleSchools.Remarks.trim()
        )
    }

    static findSchoolfromZoneData = (zonedData, zoneSchools) => {

        if (!zonedData.hasOwnProperty("SchoolInfo") || !zoneSchools) {
            return;
        }

        var zoneTypeSchoolInfo = zonedData.SchoolInfo;

        if (Array.isArray(zoneTypeSchoolInfo)) {

            var schoolsArr = [];

            zoneTypeSchoolInfo.forEach(function (zoneLocCode) {

                var schools = zoneSchools.filter((school) => {

                    return zoneLocCode.LocationCode.substring(2) === school.locationCode
                })[0]

                if (schools)
                    schoolsArr.push(schools);
            });

            return schoolsArr;
        }
        else {

            return zoneSchools.filter(function (zoneLocCode) {

                return zoneLocCode.locationCode === zoneTypeSchoolInfo.LocationCode.substring(2)
            });
        }
    }

    static showLoader = (_this) => {
        _this.refs.loaderRef.style.display = "block";
    }

    static hideLoader = (_this) => {

        setTimeout(() => {

            if (_this.refs.loaderRef)
                _this.refs.loaderRef.style.display = "none";

        }, 500);

    }

    static splitDBN = (DBNName) => {

        if (DBNName) {

            return DBNName.substring(2)
        }

        return null;
    }

    static parseLocCodes = (locations) => {

        var lst = []
        locations.map((item) => {
            lst.push(item.LocationCode)
        });

        return lst;
    }

    static getUniqueLocCode = (schoolInfo) => {

        var locCodes = [];

        var C = schoolInfo.CurrentYear;
        var N = schoolInfo.NextYear;

        var CE = C.ElementarySchools.SchoolInfo;
        var CM = C.MiddleSchools.SchoolInfo;
        var CH = C.HighSchools.SchoolInfo;

        var NE = N.ElementarySchools.SchoolInfo;
        var NM = N.MiddleSchools.SchoolInfo;
        var NH = N.HighSchools.SchoolInfo;

        var CEP = CE ? Array.isArray(CE) ? Utils.parseLocCodes(CE) : [CE.LocationCode] : null;
        var CMP = CM ? Array.isArray(CM) ? Utils.parseLocCodes(CM) : [CM.LocationCode] : null;
        var CHP = CH ? Array.isArray(CH) ? Utils.parseLocCodes(CH) : [CH.LocationCode] : null;
        var NEP = NE ? Array.isArray(NE) ? Utils.parseLocCodes(NE) : [NE.LocationCode] : null;
        var NMP = NM ? Array.isArray(NM) ? Utils.parseLocCodes(NM) : [NM.LocationCode] : null;
        var NHP = NH ? Array.isArray(NH) ? Utils.parseLocCodes(NH) : [NH.LocationCode] : null;


        locCodes = locCodes.concat(CEP, CMP, CHP, NEP, NMP, NHP);


        const uniSet = Utils.getUniqueSet(locCodes).map(a => Utils.splitDBN(a))

        return uniSet.filter((newLocCode) => {
            return newLocCode != null;
        });
    }

    static getUniqueSet = (data) => {

        if (!data) {
            return null;
        }

        return data.filter((item, index) => {

            return data.indexOf(item) === index
        })
    }

    //Select CSS classname for the school type badge in school result
    static schoolTypeBadgeCSSclass = (schoolTypes) => {
        switch (schoolTypes.toString().toLowerCase()) {
            case 'nyceec':
                return "text-black tag-color-4";
            case 'district':
                return "text-black tag-color-1";
            case 'doe':
                return "text-black tag-color-1";
            case 'charter':
                return "text-black tag-color-2";
            case 'd75':
                return "text-black tag-color-4";
            case 'district 75':
                return "text-black tag-color-4";
            case 'transfer':
                return "text-black tag-color-3";
            case 'pkc':
                return "text-black tag-color-4";
            case 'pre-k center':
                return "text-black tag-color-4";
            case 'zoned':
                return "text-white tag-color-5";
            default:
                return "text-black tag-color-5";
        }
    }

    static getTypeLabel = (schoolTypes) => {

        switch (schoolTypes.toString().toLowerCase()) {
            case 'doe':
                return "District";
            case 'pkc':
                return "Pre-K Center";
            case 'd75':
                return "District 75";
            default:
                return schoolTypes;
        }
    }

    static multiPropsFilter = (arrayData, filters) => {

        const filterKeys = Object.keys(filters);
        var retList = arrayData.filter(schoolItem => {
            return filterKeys.every(key => {
                if (!filters[key].length) return true;

                if (Array.isArray(schoolItem[key])) {

                    return schoolItem[key].some(keyEle => filters[key].includes(keyEle));
                }


                //Only for Grades with Commas Separated
                if (schoolItem[key] && schoolItem[key].indexOf(",") !== -1) {

                    var gradeArr = schoolItem[key].split(",");

                    if (Array.isArray(gradeArr)) {

                        return filters[key].some(keyEle => schoolItem[key].includes(keyEle));
                    }

                }
                //If years/ShowZOne checkbox select then show all points
                else if (key === "onlyZone" || key === "years") {
                    return arrayData
                }
                else {
                    return filters[key].includes(schoolItem[key]);
                }

            });
        });

        return retList.length === 0 ? null : retList;
    };

    static filteredCollected = (passingTags) => {

        const collectedTrueKeys = {
            grades: [],
            type: [],
            onlyZone: [],
            years: []
        };

        const { grades, type, onlyZone, years } = passingTags;

        for (let item in grades) {
            if (grades[item]) {
                collectedTrueKeys.grades.push(item)
            }
        }
        for (let item in type) {
            if (type[item]) {
                collectedTrueKeys.type.push(item);
            }
        }
        for (let item in onlyZone) {
            if (onlyZone[item]) {
                collectedTrueKeys.onlyZone.push(item);
            }
        }
        for (let item in years) {
            if (years[item]) {
                collectedTrueKeys.years.push(item);
            }
        }

        return collectedTrueKeys;
    };


    static ShowHideClearButton = () => {

        //Clear Button Hide/Show
        if (document.getElementById("clearAllBtn") != null) {
            document.getElementById("clearAllBtn").style.display = "none";
            var allChecked = document.querySelectorAll('input[type=checkbox]:checked').length;

            if (allChecked > 0) {

                document.getElementById("clearAllBtn").style.display = "block";
            }
        }
    }


    static filterZoneSchools = (zoneSchools, zoneInfo) => {

        var isCurrentChK = document.querySelectorAll('input[id="current"]:checked').length;
        var isFutureChK = document.querySelectorAll('input[id="future"]:checked').length;

        //If year both Checkboxes are unSelected or both are selected then nothing to do (display all zoneschools)
        if ((isCurrentChK === 0 && isFutureChK === 0) || (isCurrentChK !== 0 && isFutureChK !== 0)) {
            return zoneSchools;
        }
        else {

            if (isCurrentChK > 0) {
                return Utils.findZoneSchoolsByYear(zoneSchools, zoneInfo, "C")
            }
            else if (isFutureChK > 0) {
                return Utils.findZoneSchoolsByYear(zoneSchools, zoneInfo, "F")
            }
        }
    }

    static findZoneSchoolsByYear = (schools, schoolInfo, type) => {

        var strLocCodes = null;
        switch (type) {

            case "C":
                var C = schoolInfo.CurrentYear;
                var CE = C.ElementarySchools.SchoolInfo;
                var CM = C.MiddleSchools.SchoolInfo;
                var CH = C.HighSchools.SchoolInfo;

                var CEP = CE ? Array.isArray(CE) ? Utils.parseLocCodes(CE) : [CE.LocationCode] : null;
                var CMP = CM ? Array.isArray(CM) ? Utils.parseLocCodes(CM) : [CM.LocationCode] : null;
                var CHP = CH ? Array.isArray(CH) ? Utils.parseLocCodes(CH) : [CH.LocationCode] : null;

                var CLocCodes = [];
                strLocCodes = CLocCodes.concat(CEP, CMP, CHP).join();


                break;

            case "F":

                var N = schoolInfo.NextYear;

                var NE = N.ElementarySchools.SchoolInfo;
                var NM = N.MiddleSchools.SchoolInfo;
                var NH = N.HighSchools.SchoolInfo;

                var NEP = NE ? Array.isArray(NE) ? Utils.parseLocCodes(NE) : [NE.LocationCode] : null;
                var NMP = NM ? Array.isArray(NM) ? Utils.parseLocCodes(NM) : [NM.LocationCode] : null;
                var NHP = NH ? Array.isArray(NH) ? Utils.parseLocCodes(NH) : [NH.LocationCode] : null;

                var FLocCodes = [];
                strLocCodes = FLocCodes.concat(NEP, NMP, NHP).join();


                break;
        }

        return schools.filter(function (school) {
            return (strLocCodes.indexOf(school.locationCode) !== -1)
        })
    }

    static GetSetZoneStatus = () => {

        var isCurrentChK = document.querySelectorAll('input[id="current"]:checked').length;
        var isFutureChK = document.querySelectorAll('input[id="future"]:checked').length;

        //If year both Checkboxes are unSelected or both are selected then nothing to do (display all zoneschools)
        if ((isCurrentChK === 0 && isFutureChK === 0) || (isCurrentChK !== 0 && isFutureChK !== 0)) {

            return "B"
        }
        else {
            if (isCurrentChK > 0) {
                return "C"

            }
            else if (isFutureChK > 0) {

                return "F"

            }
        }
    }

}
