import React, { useContext } from 'react';
import { Filters } from './Filters';
import { SchoolList } from './SchoolList';
import ZoneSchoolContainer from './ZoneSchoolContainer';
import LeftNotes from './LeftNotes';
import { DataContext } from "../common/context/DataContext";

const LeftNav = () => {

    const context = useContext(DataContext);

    function handleClick(e) {
        e.preventDefault();
        if (document.documentElement.classList.contains('translated-ltr') && e.target.parentNode.parentNode.id === 'nav-map-tab') {
            document.body.classList.add('smallMap');
        } else if (e.target.id === 'nav-map-tab') {
            document.body.classList.add('smallMap');
        } else {
            document.body.classList.remove('smallMap');
        }
    }

    return (

        <section id="tabs" className="filter-tab">

            <nav>
                <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                    <a onClick={handleClick} className="nav-item nav-link" id="nav-map-tab" data-toggle="tab" href="#nav-map" role="tab" aria-controls="nav-map" aria-selected="false">Map</a>
                    <a onClick={handleClick} className="nav-item nav-link active" id="nav-school-tab" data-toggle="tab" href="#nav-school" role="tab" aria-controls="nav-school" aria-selected="true">Schools</a>
                    <a onClick={handleClick} className="nav-item nav-link" id="nav-filters-tab" data-toggle="tab" href="#nav-filters" role="tab" aria-controls="nav-filters" aria-selected="false">Filters</a>
                </div>
            </nav>

            <div className="tab-content" id="nav-tabContent">

                <div className="tab-pane fade nav-map" id="nav-map" role="tabpanel" aria-labelledby="nav-map-tab"></div>

                <div className="tab-pane fade nav-school active show" id="nav-school" role="tabpanel" aria-labelledby="nav-school-tab">

                    {context.HasError ? <div className="alert alert-danger alert-dismissible fade show"> No record found{context.action === "radius" ? " within 1 mile of above address!" : "!"}</div> : null}

                    {
                        context.filterData && !context.filterZoneData ? (<SchoolList schoollist={context.filterData} />) // If only Schools
                            : (context.filterData || context.filterZoneData) ? (<ZoneSchoolContainer />) : null // IF ZOne and Schools
                    }

                    {!context.filterData && !context.filterZoneData ? (<LeftNotes />) : null}

                </div>

                <div className="tab-pane fade nav-filters" id="nav-filters" role="tabpanel" aria-labelledby="nav-filters-tab">
                    {
                        (context.schoolData && context.schoolData.length > 1) || (context.zoneInfo && context.zoneSchoolsData && context.zoneSchoolsData.length > 1)
                            ?
                            (<Filters />)
                            :
                            <div className="schoolInfoDiv" id="schoolInfoDiv">
                                Filters will be available after you search for school or address.
                            </div>
                    }

                </div>
            </div>

        </section>
    );

}

export default LeftNav