import React from 'react';
const Loader = () => {
    return (
        <div className="loadingSpinner">
            <div className="loader m-5" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
};

export default Loader; 