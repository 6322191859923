import React, { Component } from 'react';
import "babel-polyfill";
import { Header } from './Header';
import { Footer } from './Footer';
import { Container } from './Container';
import { DataProvider } from "../common/context/DataContext";

export class Layout extends Component {
    static displayName = Layout.name;   

    render() {

        return (
            <>
                <div className="container-fluid headerContainer">
                    <Header />
                </div>
                <div className="container-fluid border-bottom"></div>
                <DataProvider>
                    <Container />
                </DataProvider>
                <div className="container-fluid border-bottom"></div>
                <Footer />
            </>

        );
    }
}
