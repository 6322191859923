import React, { createContext } from 'react';
import DataService from "../service/DataService";
import 'es6-promise';
import fetch from "isomorphic-fetch"

export const DataContext = createContext();

var initialState = {
    schoolData: null,
    filterData: null,
    zoneInfo: null,
    zoneSchoolsData: null,
    hoverItem: null,
    action: null,
    HasError: null,
    Error: null,
    selectedAddress: null,
    selectedGrade: null,
    resetlist: null,
    zoneStatus: null,
    config: null
}

export class DataProvider extends React.Component {

    constructor(props) {

        super(props);
        this.state = initialState; 
       
    }

    getConfig = async () => {

        try {
            const response = await fetch("./Home");
            const data = await response.json();

            var configuration = {

                Data: data.filter(obj => { return obj.key === "Data" })[0].value,
                Map: data.filter(obj => { return obj.key === "Map" })[0].value,
                Geometry: data.filter(obj => { return obj.key === "Geometry" })[0].value
            }

            this.setState({ config: configuration , action : "setconfig" });

        }
        catch (error) {
            this.setState({ config : null });
        }
     };

    componentDidMount() {

        this.getConfig();
    }

    resetState = (action = null) => {

        initialState = initialState = {
            schoolData: null,
            filterData: null,
            filterZoneData: null,
            zoneInfo: null,
            zoneSchoolsData: null,
            hoverItem: null,
            action: action,
            HasError: null,
            Error: null,
            selectedAddress: null,
            selectedGrade: null,
            resetlist: null,
            zoneStatus: null
        }

        this.setState(initialState)
    }

    setSchoolsData = (schools, action, reset = true) => {
        this.setState({
            schoolData: schools,
            filterData: schools,
            selectedAddress: null,
            selectedGrade: null,
            zoneSchoolsData: null,
            zoneInfo: null,
            zoneStatus: null,
            resetlist: reset,
            action: action,
        })
    }

    setSchoolZoneData = (schools, zonesSchools, zoneInfo, selectedGrade) => {

        this.setState({
            schoolData: schools,
            zoneSchoolsData: zonesSchools,
            filterZoneData: zonesSchools,
            filterData: schools,
            zoneInfo: zoneInfo,
            action: "radius",
            selectedAddress: null, ////////// Set Address Null bcoz of inifinit loop on componentDidUpdate,
            selectedGrade: selectedGrade,
            HasError: (!schools && !zonesSchools)
        })

    }

    setfilterData = (schoolData, zoneData, reset, hasError, zoneStatus) => {
        this.setState({
            filterData: schoolData,
            filterZoneData: zoneData,
            selectedAddress: null,
            //selectedGrade: null,                
            resetlist: reset,
            HasError: hasError,
            zoneStatus: zoneStatus,
            action: "filter",
        })
    }



    mouseOvers = (locCode) => {

        this.setState({
            hoverItem: locCode,
            resetlist: null,
            action: "smouseover",
        })
    }

    mouseOut = () => {

        this.setState({
            hoverItem: null,
            resetlist: null,
            action: "smouseout",
        })
    }

    clickAddress = (address, grade) => {

        this.resetState();

        this.setState({
            selectedAddress: address,
            selectedGrade: grade,
            action: "address"
        })
    }

    openPopup = (locCode) => {

        this.setState({
            hoverItem: locCode,
            action: "openPopup",
        })
    }

    resetAction = (action) => {

        this.setState({
            action: action
        })
    }

    setConfig = (configuration) => {

        this.setState({
            config: configuration
        })
    }

    getSchools = (url, request) => {

        return DataService.GetSchools(url, request).then(schools => {

            this.setState({ schoolData: schools, HasError: false, filterData: schools, selectedGrade: request.grade, action: "search" })

        }).catch(err => {

            this.setState({ schoolData: null, filterData: null, selectedGrade: request.grade, action: "search", HasError: true })
        });
    }

    render() {
        return (
            <DataContext.Provider value={{
                ...this.state, 
                getSchools: this.getSchools,
                setSchoolsData: this.setSchoolsData,
                mouseOvers: this.mouseOvers,
                mouseOut: this.mouseOut,
                clickAddress: this.clickAddress,
                resetState: this.resetState,
                setSchoolZoneData: this.setSchoolZoneData,
                setfilterData: this.setfilterData,
                openPopup: this.openPopup,
                resetAction: this.resetAction,
                setConfig: this.setConfig
            }}>
                {this.props.children}
            </DataContext.Provider>

        )
    }
}

export const DataConsumer = DataContext.Consumer;