import React from 'react';

const LeftNotes = () => {

    return (
        <div className="schoolInfoDiv" id="schoolInfoDiv">
            <span className="txt-title">Instructions:</span>
            <ul>
                <li>Type any address or school name above then click Search.</li>
                <li>Select a grade and borough to better focus your results.</li>
                <li>To change the map view, click the Neighborhoods or Districts button.</li>
                <li>For subway lines, school zones and borough lines, click the menu button in the top right corner.</li>
             </ul>
        </div>
    )

}

export default LeftNotes; 

