import { loadModules } from 'esri-loader';
import Utils from './Utils.js';
import config from "../../configuration/webconfig.json";

export default class MapHelper {

    static highlightPoint = null;
    static highlightArea = null;

    static displaySchoolPoints = (schools, type, _this) => {

        return new Promise((resolve, reject) => {

            if (schools.length > 0) {

                loadModules(["esri/geometry/Point", "esri/Graphic"]).then(([Point, Graphic]) => {

                    schools.map(function (item, i) {

                        var point = new Point({
                            x: item.x,
                            y: item.y,
                            spatialReference: _this.view.spatialReference
                        });

                        var attributes = {
                            Name: item.name,
                            Profile: "<a href='https://www.schools.nyc.gov/schools/" + item.locationCode + "' class= \'btn btn-primary\' target='_blank'>Full School Profile</a>",
                            LocCode: item.locationCode,
                            Address: item.primaryAddressLine + " " + item.boroughName + " " + item.zip,
                            Email: item.Email,
                            Type: item.type,
                            Phone: item.phoneNumber,
                            Note: item.Note,
                            District: item.district,
                            Grades: item.grades,
                            Neighborhood: item.neighborhood,
                            iconType: type
                        };

                        const badgeClass = Utils.schoolTypeBadgeCSSclass(item.type);

                        const typeLabel = Utils.getTypeLabel(item.type);

                        let template = {
                            title: "<b>{Name}</b> - <b>{LocCode}</b> <div class=\'badge badge-text " + badgeClass + "' >" + typeLabel,
                            content: [
                                {
                                    type: "fields",
                                    fieldInfos: [
                                        {
                                            fieldName: "Address",
                                            label: "Address"
                                        },
                                        {
                                            fieldName: "Neighborhood",
                                            label: "Neighborhood"
                                        },
                                        {
                                            fieldName: "District",
                                            label: "District"
                                        },
                                        {
                                            fieldName: "Phone",
                                            label: "Phone Number",
                                            format: "phone-number"
                                        },
                                        {
                                            fieldName: "Grades",
                                            label: "Grades"
                                        },
                                        {
                                            fieldName: "Profile",
                                            label: "Profile"
                                        }
                                    ]
                                }
                            ]
                        };


                        var symbol = config.symbol.schoolPin

                        if (type === "Z") {
                            symbol = config.symbol.zonePin
                        }

                        // Create a graphic and add the geometry and symbol to it
                        const graphic = new Graphic({
                            symbol: symbol,
                            geometry: point,
                            attributes: attributes,
                            popupTemplate: template,
                            id: type,
                        })


                        _this._iconLayer.add(graphic)

                        if (schools.length - 1 === i) {
                            resolve("done")
                        }

                    });

                });

            }

        })


    }

    static createPoint = (symbol, point, _this, id = null) => {

        return new Promise((resolve, reject) => {

            loadModules(['esri/Graphic']).then(([Graphic]) => {

                _this._iconLayer.add(

                    new Graphic({
                        geometry: point,
                        symbol: symbol,
                        id: id
                    })
                )

                resolve();
            })

        })
    }

    static getpoint = (X, Y) => {
        return new Promise((resolve) => {
            loadModules(["esri/geometry/Point"]).then(([Point]) => {

                resolve(new Point({
                    x: X,
                    y: Y,
                    spatialReference: { wkid: 2263 }
                }))
            });
        });
    }

    static getQueryTask = (url, outFields, whereClause = "", _this) => {

        return new Promise((resolve, reject) => {

            loadModules(["esri/tasks/QueryTask", "esri/tasks/support/Query"]).then(([QueryTask, Query]) => {

                _this.queryTask = new QueryTask({
                    url: url
                });

                var params = new Query({
                    returnGeometry: true,
                    outFields: outFields,
                    where: whereClause,
                    outSpatialReference: _this.view.spatialReference
                });

                _this.queryTask.execute(params).then(function (respFeature) {

                    resolve(respFeature);
                })
            });

        });

    }

    static clearLayers = (_this) => {

        if (_this._modeLayer) {
            _this._modeLayer.removeAll();
        }

        if (_this._iconLayer) {
            _this._iconLayer.removeAll();
        }

        if (_this._activeModeLayer) {
            _this._activeModeLayer.removeAll();
        }

        if (_this.view && _this.view.popup) {
            _this.view.popup.close();
        }
        

        _this.refs.infoDiv.innerText = "";
        _this.refs.mapInfoContainer.style.display = "none";
    }


    static getProjectParameter = (points, _this) => {

        return new Promise((resolve, reject) => {

            loadModules(["esri/tasks/support/ProjectParameters"])
                .then(([ProjectParameters]) => {

                    var projectparams = new ProjectParameters({

                        geometries: points,
                        outSpatialReference: _this.view.spatialReference,
                        transformation: { wkid: 1188 },
                        transformForward: true,
                    });

                    _this._geometryService.project(projectparams)
                        .then(function (projectedpoints) {

                            resolve(projectedpoints);

                        }).catch(function (error) {

                            console.log("ProjectParameters Error:" + error)
                            reject("projection rejected")
                        });
                });

        })

    }

    static displayRadius = (projectedpoints, _this) => {

        _this._activeModeLayer.removeAll();

        return new Promise((resolve, reject) => {

            loadModules(['esri/Graphic', "esri/tasks/support/BufferParameters", "esri/tasks/GeometryService"])
                .then(([Graphic, BufferParameters, GeometryService]) => {

                    var paramsForRaduis = new BufferParameters({
                        geometries: [projectedpoints],
                        distances: [1],
                        unit: GeometryService.UNIT_STATUTE_MILE,
                        bufferSpatialReference: _this.view.spatialReference,
                        outSpatialReference: _this.view.spatialReference,
                    });


                    _this._geometryService.buffer(paramsForRaduis)
                        .then(function (polygonRadius) {

                            //If Radius is found then Create Radious on Map
                            if (polygonRadius.length > 0) {


                                var addressRadius = new Graphic({
                                    geometry: polygonRadius[0],
                                    symbol: _this._radiusSymbol
                                });

                                //_this._activeModeLayer.add(addressRadius);

                                _this.view.goTo(addressRadius, { duration: 500 });


                                //Return Polygon (geomertry for Radius to to schools)
                                resolve(addressRadius.geometry);
                            }


                        })
                        .catch(function (error) {

                            console.log("BufferParameters Radius Error:" + error);
                        });

                });
        });
    }

    static getNearBySchoolsByGeometry = (geometry, outFields, _this) => {

        return new Promise((resolve, reject) => {

            loadModules(["esri/tasks/support/Query"]).then(([Query]) => {

                var query = new Query({
                    returnGeometry: false,
                    outSpatialReference: _this.view.spatialReference,
                    outFields: outFields,
                    geometry: geometry,
                });
                 
                _this._schoolQueryTask.execute(query)
                    .then(function (results) {

                        var locationCodes = results.features.map(function (item) {

                            return item.attributes.LOC_CODE;
                        }).sort();

                        resolve(locationCodes)

                    })
                    .catch(function (error) {

                        console.log("School Lables Location Error:" + error);
                    });
            });

        });
    }


    static mouseHoverPoint = (hoverItem, _this) => {

        _this.view.whenLayerView(_this._iconLayer).then(function (layerView) {

            var feature = layerView.graphicsView.graphics.items.filter(function (item) {

                return item.attributes && item.attributes["LocCode"] === hoverItem;
            })

            MapHelper.highlightPoint = layerView.highlight(
                feature
            );

        });
    }


    static mouseOutPoint = () => {

        if (MapHelper.highlightPoint) {
            MapHelper.highlightPoint.remove();
            MapHelper.highlightPoint = null;
        }
    }


    static mouseOutModeLayer = (_this) => {

        if (MapHelper.highlightArea) {
            MapHelper.highlightArea.remove();
            MapHelper.highlightArea = null;
        }

        _this.refs.mapInfoContainer.style.display = "none";
    }

    static onHoverMap = (response, _this) => {

        if (response.results.length) {

            /////// Icon Hover
            _this.view.whenLayerView(_this._iconLayer).then(function (layerView) {

                var pointfeature = response.results.filter(function (result) {

                    return result.graphic.layer === _this._iconLayer;
                });

                //Reset point symbol
                MapHelper.mouseOutPoint()

                if (pointfeature.length > 0) {

                    MapHelper.highlightPoint = layerView.highlight(
                        pointfeature[0].graphic
                    );
                }
            });


            /////// Mode Layer Hover
            _this.view.whenLayerView(_this._modeLayer).then(function (layerView) {

                var modefeature = response.results.filter(function (result) {

                    return result.graphic.layer === _this._modeLayer;
                });

                //Reset Mode symbol
                MapHelper.mouseOutModeLayer(_this)

                if (modefeature.length > 0) {

                    MapHelper.highlightArea = layerView.highlight(
                        modefeature[0].graphic
                    );

                    //Open Popup for this
                    _this.refs.infoDiv.innerText = _this._mode === 2 ? "Neighborhood : " + modefeature[0].graphic.attributes.PjAreaName : " District : " + modefeature[0].graphic.attributes.SCHOOLDIST;
                    _this.refs.mapInfoContainer.style.display = "block";
                }

            });

        }
        else {

            //Reset point symbol
            MapHelper.mouseOutPoint();

            //Reset Mode symbol
            MapHelper.mouseOutModeLayer(_this)
        }
    }

    static hideAllPoints = (_this) => {

        _this._iconLayer.graphics.items.filter(x => { return x.id !== "addressP" }).map(p => {

            p.visible = false
        });
    }

    static showPointsByType = (schools, type, _this) => {

        if (schools) {

            schools.map(school => {

                _this._iconLayer.graphics.items.filter(x => { return x.id === type && x.attributes.LocCode === school.locationCode }).map(p => {

                    p.visible = true
                });

            });
        }
    }


}