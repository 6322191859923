import React, { Component } from 'react';
import Zone from './Zone';
import Utils from "../common/utils/Utils";
import { DataContext } from "../common/context/DataContext";

export class ZoneList extends Component {

    static contextType = DataContext

    constructor(props) {

        super(props);

        this.state = { isZoneEqual: null };
    }

    componentDidMount() {

        const isZoneEqual = Utils.isZoneEqual(this.context.zoneInfo.CurrentYear, this.context.zoneInfo.NextYear);

        this.setState({
            isZoneEqual: isZoneEqual
        })

        document.getElementById("zonedDDDiv").style.display = "block";
        document.getElementById("yearDDDiv").style.display = "none";
        document.querySelectorAll('input[id="IsZone"]')[0].checked = true;
        document.querySelectorAll('input[id="current"]')[0].checked = true;
        document.querySelectorAll('input[id="future"]')[0].checked = false;
    }

    componentDidUpdate() {

        switch (this.context.action) {

            case "filter":

                if (!this.state.isZoneEqual || this.state.isZoneEqual === false) {

                    document.getElementById("yearDDDiv").style.display = "block";
                }
                break;

            case "radius":

                if (!this.state.isZoneEqual || this.state.isZoneEqual === false) {

                    document.getElementById("yearDDDiv").style.display = "block";
                    document.querySelectorAll('input[id="current"]')[0].checked = true;
                    document.querySelectorAll('input[id="future"]')[0].checked = true;
                }

                break;
        }
    }


    render() {

        const zoneStatus = this.context.zoneStatus ? this.context.zoneStatus : "B";

        return (
            <div>

                {zoneStatus === "B" || zoneStatus === "C" ?
                    <Zone zoneData={this.context.zoneInfo.CurrentYear} zoneSchools={this.context.filterZoneData} yearType="Current" />
                    : null
                }

                {zoneStatus === "B" || zoneStatus === "F" ?
                    this.state.isZoneEqual != null && !this.state.isZoneEqual ? <Zone zoneData={this.context.zoneInfo.NextYear} zoneSchools={this.context.filterZoneData} yearType="Future" /> : null
                    : null
                }

            </div >
        )

    }
}
