import React, { Component } from 'react';
import { BreadCrumb } from './BreadCrumb';
import LeftNav from './LeftNav';
import { Search } from './Search';
import { MapView } from './MapView';

export class Container extends Component {

    constructor(props) {

        super(props);
        this.state = { showMenu: false };
    }

    ToggleMenu = () => {
        this.setState({ showMenu: !this.state.showMenu })
    }

    render() {
        const menuVis = this.state.showMenu ? 'd-none' : 'd-block';
        const handlerBtn = this.state.showMenu ? 'collapseBtn' : 'expandBtn';

        return (
            <div className="container-fluid overFlowContainer">
                <BreadCrumb />
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div class="col-12 col-sm-10 site--main-header"><h1>Find a School</h1></div>
                            <div class="col-12 col-sm-2 site--main-header">
                                <p class="float-right"><input type="submit" class="btn btn-primary" value="Submit your feedback" onClick={event => window.open('https://www.surveygizmo.com/s3/5379915/School-Search-Feedback')} /></p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Search />
                            </div>
                        </div>
                        <div className="main--content pt-3">
                            <div className="d-flex flex-row">
                                <div id="toggleTabs" className={`flex-grow-1 ${menuVis}`}>
                                    <LeftNav />
                                </div>
                                <div className="position-relative">
                                    <button onClick={this.ToggleMenu} id="handleBtn" className={`handleBtn ${handlerBtn}`} aria-label="Collapse side panel"></button>
                                </div>
                                <div aria-hidden="true" className="showMap">
                                    <MapView />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}