import React, { useContext, useRef } from 'react';
import { DataContext } from "../common/context/DataContext";

const AddressView = (props) => {

    const context = useContext(DataContext)
    const inputRef = useRef(null);

    function clickAddress(item, grade, e) {

        var childs = inputRef.current.childNodes;

        for (var x = 0; x < childs.length; x++) {

            childs[x].className = ""
        }

        e.target.classList.add("font-weight-bold");

        context.clickAddress(item, grade)

    }

    return (

        <div className="chooseAddress" id="addressDiv">
            {
                props.addressData.length > 1 ?
                    <div className="" id="accordionExample">
                        <div className="">
                            <div className="addressHeader" id="headingOne">
                                <button className="addressSelectHeader" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    {props.addressData.length > 1 ? "Did you mean... ?" : null}
                                    <span className="Accordion-icon"></span>
                                </button>
                            </div>
                        </div>
                    </div> : null
            }
            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div ref={inputRef}>
                    {
                        props.addressData.map(function (item, i) {

                            return (
                                <button type="button" className={props.addressData.length === 1 ? "font-weight-bold" : ""} key={i} onClick={(e) => clickAddress(item, props.grade, e)} > {item.address} </button>
                            )
                        })

                    }
                </div>
            </div>
        </div>
    )
}

export default AddressView;